import Vue from 'vue'
import VueRouter from 'vue-router'

import Wishlist from '@/components/Wishlist'
import WishlistByPerson from '@/components/WishlistByPerson'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'wishlists',
    component: Wishlist
  },
  {
    path: '/:person',
    name: 'wishlist',
    component: WishlistByPerson
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
