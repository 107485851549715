import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDYY6eNnyhsfgQMDLIt5HtfKbE8z5JQzjA',
  authDomain: 'wishlists-bf417.firebaseapp.com',
  databaseURL: 'https://wishlists-bf417-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'wishlists-bf417',
  storageBucket: 'wishlists-bf417.appspot.com',
  messagingSenderId: '518978110719',
  appId: '1:518978110719:web:91a07932e39fa27432b8ca',
  measurementId: 'G-measurement-id'
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

export const db = firebaseApp.firestore()
