<template>
  <div class="products">
    <div class="table-responsive-sm">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              Artikel
            </th>
            <th
              scope="col"
              class="d-none d-sm-table-cell"
            >
              Beschreibung
            </th>
            <th>
              Wunsch von
            </th>
            <th
              scope="col"
              class="d-none d-sm-table-cell"
            >
              Shop
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="wish in wishes"
            :key="wish.ID"
          >
            <template>
              <td>
                {{ wish.Article }}
              </td>
              <td
                scope="col"
                class="d-none d-sm-table-cell"
              >
                {{ wish.Description }}
              </td>
              <td>
                {{ wish.Person | capitalize }}
              </td>
              <td
                scope="col"
                class="d-none d-sm-table-cell"
              >
                {{ wish.Shop }}
              </td>
              <td>
                <a
                  v-if="wish.Link && wish.Link.length > 0"
                  :href="wish.Link"
                  class="btn btn-outline-info"
                  role="button"
                  target="_blank"
                > Link </a>
              </td>
              <td>
                <button
                  v-if="!wish.HideDelete" 
                  class="btn btn-danger"
                  @click.prevent="onDelete(wish.ID, wish.Article)"
                >
                  Gekauft?
                </button>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { db } from '../firebaseDb'

export default {
  filters: {
  capitalize: function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
},
  data () {
    return {
      wishes: [],
      errors: []
    }
  },

  // Fetches posts when the component is created.
  created () {
    db.collection('wishlist').where('priv', '!=', true).onSnapshot((snapshotChange) => {
      this.wishes = []
      snapshotChange.forEach((doc) => {
        this.wishes.push({
          ID: doc.id,
          Article: doc.data().item_name,
          Description: doc.data().item_description,
          Person: doc.data().user_name,
          Shop: doc.data().shop,
          Link: doc.data().item_link,
          HideDelete: doc.data().hide_delete
        })
      })
    })
  },
  methods: {
    onDelete (id, item) {
      if (window.confirm('Soll der Artikel "' + item + '" entfernt werden?')) {
        db.collection('wishlist').doc(id).delete().then(() => {
          console.log('Document ' + id + ' deleted!')
        })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3{
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.icon{
  margin-right: 10px;
}
.icon i{
  cursor: pointer;
}
</style>
